.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background: #4E4A47;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #FF8769;
}