
@font-face {
  font-family: Montserrat;
  src: local("Montserrat"), url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: regular;
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  padding: 10px;
  margin: 5px;
}
